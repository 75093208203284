import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

// css
const Root = styled.div`
  padding: 40px 0 100px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Container = styled.div`
  max-width: 680px;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Message = styled.div`
  position: relative;
  width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #e9f7ff;
  /* box-shadow: 0 0 16px 0 rgba(0,0,0,0.15); */
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 20px solid #e9f7ff;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
  }
`

const ImgContainer = styled.div`
  width: 80px;
  height: 80px;
  margin: 24px 0 12px 0;
`

const Title = styled.h1`
  margin-bottom: 12px;
  color: #222222;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
`

const Text = styled.p`
  margin-bottom: 40px;
  color: #222222;
  font-size: 16px;
  letter-spacing: 0.37px;
  line-height: 32px;
  text-align: center;
`

const PhotoContainer = styled.div`
  box-sizing: border-box;
  height: 74px;
  width: 74px;
  border: 3px solid #404ca9;
  border-radius: 191px;
  background-color: #d8d8d8;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  margin: 30px 0 40px 0;
`

const Return = styled.button`
  height: 45px;
  width: 100px;
  border-radius: 22.5px;
  background-color: #efefef;
  /* box-shadow: 0 0 16px 0 rgba(0,0,0,0.15); */
  border: 0;
  color: #222222;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 45px;
  text-align: center;
`

// component
const Success = (props) => {
  return (
    <div>
      <Root>
        <Container>
          <Message>
            <ImgContainer>
              <StaticImage
                src="../../images/check_circle.png"
                alt="check"
                placeholder="blurred"
                width={80}
                height={80}
              />
            </ImgContainer>
            <Title>お問い合わせが送信されました</Title>
            <Text>
              お問い合わせいただき、ありがとうございます。
              <br />
              お問い合わせ頂いた内容については、確認の上、ご返信させていただきます。
            </Text>
          </Message>

          <PhotoContainer>
            <GatsbyImage
              image={props.photo}
              alt="送信完了"
              style={{ borderRadius: 191, height: "100%" }}
              imgStyle={{ borderRadius: 191 }}
            />
          </PhotoContainer>

          <Link to="/">
            <Return>戻る</Return>
          </Link>
        </Container>
      </Root>
    </div>
  )
}

export default Success
