import React from "react"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

// css
const Message = styled.div`
  position: relative;
  height: 68px;
  width: 475px;
  margin: 40px 0 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`
const ChatContainer = styled.div`
  height: 54px;
  width: 420px;
  @media screen and (max-width: 799px) {
    height: 76px;
    width: 267px;
  }
`

const Chat = styled.div`
  position: relative;
  height: 54px;
  width: 389px;
  padding: 16px 19px 0 15px;
  border-radius: 8px;
  background-color: #bee9ff;
  /* box-shadow: 0 0 16px 0 rgba(0,0,0,0.15); */
  @media screen and (max-width: 799px) {
    height: 76px;
    width: 257px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 389px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #bee9ff;
    @media screen and (max-width: 799px) {
      left: 257px;
    }
    @media screen and (max-width: 346px) {
      display: none;
    }
  }
`

const ChatText = styled.p`
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.35px;
  line-height: 22px;
  text-align: center;
`
const PhotoBoder = styled.div`
  margin-left: 8px;
  box-sizing: border-box;
  height: 74px;
  width: 74px;
  border: 3px solid #404ca9;
  border-radius: 191px;
  background-color: #d8d8d8;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 346px) {
    display: none;
  }
`

// component
const TopMessage = (props) => {
  return (
    <Message>
      <ChatContainer>
        <Chat>
          <ChatText>システムに関するお困りごとをお聞かせください。</ChatText>
        </Chat>
      </ChatContainer>
      <PhotoBoder>
        <GatsbyImage
          image={props.photo}
          alt="対話"
          style={{ borderRadius: 191, height: "100%" }}
          imgStyle={{ borderRadius: 191 }}
        />
      </PhotoBoder>
    </Message>
  )
}

export default TopMessage
