import React from "react"
import styled from "@emotion/styled"
import Seo from "../components/seo"
import TopMessage from "../components/contact/top-message"
import ContactForm from "../components/contact/contactform"
import { UsePageContext } from "../context/page-context"
import { useEffect } from "react"
import { useState } from "react"
import Success from "../components/contact/success"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

// css
const Root = styled.div`
  padding: 40px 0 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 799px) {
    padding-bottom: 100px;
  }
`

const Container = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  height: 45px;
  /* width: 680px; */
  color: #222222;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 45px;
  text-align: center;
  margin-bottom: 0;
`

// component
const Contact = () => {
  const { setIsAtContactPage } = UsePageContext()
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    setIsAtContactPage(true)
  }, [])

  const data = useStaticQuery(graphql`
    query PhotoQuery {
      allContentfulFacePhoto(sort: { fields: order }) {
        edges {
          node {
            name
            order
            photo {
              gatsbyImageData(width: 68, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)
  const facePhotos = data.allContentfulFacePhoto.edges
  const totalNum = facePhotos.length
  const today = new Date()
  const index = today.getDate() % totalNum
  const photo = getImage(facePhotos[index].node.photo)

  return (
    <div>
      <Seo
        title="お問い合わせ"
        description="株式会社アクアのお問い合わせです。"
      />
      {!isSubmitted && (
        <Root>
          <Container>
            <Title>対話をはじめる</Title>
            <TopMessage photo={photo} />
            <ContactForm setIsSubmitted={setIsSubmitted}></ContactForm>
          </Container>
        </Root>
      )}
      {isSubmitted && <Success photo={photo} />}
    </div>
  )
}

export default Contact
